<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12">
        <v-text-field
          label="Title"
          readonly
          v-model="video.title"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="7" lg="7" md="7" sm="12">
        <v-text-field
          label="Description"
          readonly
          v-model="video.description"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="1" lg="1" md="1" sm="12">
        <v-btn outlined class="mr-4" @click="onBack">
          <v-icon>mdi-backspace</v-icon>Back</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <video id="video" controls />
    </v-row>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
// import convert from "@/_services/convert-service";
import router from "@/router";
import store from "@/store";

export default {
  mounted() {
    this.get();
  },

  data: () => ({
    video: {},
  }),

  methods: {
    onBack() {
      router.go(-1);
    },


    get() {
      const url = "video/" + this.$route.params.id;
      apiService.get(url, this.getSuccess, this.getError);
    },

    getError(error) {
      const notification = {
        title: "Error getting the video",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getSuccess(result) {
      this.video = result.data;
      const url = "video/download/" + this.video.id;
      apiService.get(url, this.getFileSuccess, this.getError);
    },

    getFileSuccess(result) {
      var binaryString = window.atob(result.data);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }

      this.set(bytes);
    },

    set(contents) {
      var video = document.getElementById("video");
      var blob = new Blob([contents]);
      video.src = URL.createObjectURL(blob);
      video.play();
    },
  },
};
</script>

<style>
</style>